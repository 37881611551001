import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Card, CardBody, Table, Button } from 'reactstrap';
import moment from 'moment'
import { api2 } from '~/services/api'
import Loader from '~/components/Loader/Loader';
import { toast } from 'react-toastify'
import { Container } from './styles'
import NewAdvertisingModal from './NewAdvertisingModal';
import { adTypes } from './utils';

function AdvertisingScreen() {
  const [loading, setLoading] = useState(false)
  const [advertisings, setAdvertisings] = useState([])
  const [benefitModal, setBenefitModal] = useState(false)
  const [editId, setEditId] = useState(null)

  const loadAds = useCallback(async () => {
    try {
      setLoading(true)

      const { data } = await api2.get(`advertising`)

      console.log(data)
      setAdvertisings(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error('Erro ao carregar as propagandas')
    }
  }, [])

  const removeAd = async (id) => {
    let result = window.confirm("Tem certeza que deseja remover permanentemente essa propaganda?");
    if (!result) return;
    api2.delete(`advertising/${id}`)
      .then(() => {
        loadAds();
        toast.success('Propaganda removida com sucesso!');
      })
      .catch((err) => {
        console.log(err);
        toast.error('Erro ao remover a propaganda')
      })
  }

  const openEditModal = (id) => {
    setEditId(id)
    setBenefitModal(true)
  }

  const closeModal = () => {
    setEditId(null)
    setBenefitModal(false)
  }

  useEffect(() => {
    loadAds()
  }, [loadAds])

  const formatUserSubscriptionType = (type) => {
    switch (type) {
      case 'ESSENTIAL':
        return 'Essencial';
      case 'PERSONAL':
        return 'Personal';
      case 'FREE':
        return 'Gratuito';
      case 'BASIC':
        return 'Básico';
      default:
        return '';
    }
  }

  return (
    <Container>
      {benefitModal && <NewAdvertisingModal loadAds={loadAds} open={benefitModal} toggle={closeModal} editId={editId} formatUserSubscriptionType={formatUserSubscriptionType} />}
      <Row>
        <Col>
          <div className="page-title-box">
            <Row>
              <Col lg={7}>
                <h4 className="page-title">Benefícios</h4>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          <Card>
            <CardBody>
              {loading && <Loader />}
              <Button style={{ marginBottom: 10 }} color="success" onClick={() => setBenefitModal(true)}>Cadastrar nova propaganda</Button>
              <Row>
                <Table hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome</th>
                      <th>Status</th>
                      <th>Tipo</th>
                      <th>Estado(s)</th>
                      <th>Link</th>
                      <th>Validade</th>
                      <th>Tipo(s) de assinatura</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {advertisings && advertisings.length > 0 && advertisings.map((ad, index) => (
                      <tr key={ad._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{ad.title}</td>
                        <td>{ad.active ? 'Ativo' : 'Desativado'}</td>
                        <td>{ad.type ? adTypes.find(t => t.value === ad.type).name : '-'}</td>
                        <td>
                          {
                            !!ad.locations?.length ?
                              <>
                                {
                                  ad.locations.join(',')
                                }
                              </>
                              :
                              'Todos'
                          }
                        </td>
                        <td><a href={ad.action_url}><b>Link</b></a></td>
                        <td>{ad.expiration_date ? moment(ad.expiration_date).format('DD/MM/YYYY') : '-'}</td>
                        <td>
                          {
                            !!ad.subscription_types?.length ?
                              <>
                                {
                                  ad.subscription_types.map(value => (formatUserSubscriptionType(value))).join(',')
                                }
                              </>
                              :
                              'Todos'
                          }
                        </td>
                        <td className="tabledit-view-mode">
                          <Button color="primary" onClick={() => openEditModal(ad._id)}>Editar</Button>
                        </td>
                        <td className="tabledit-view-mode">
                          <Button color="danger" onClick={() => removeAd(ad._id)}>Excluir</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AdvertisingScreen;