import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, Badge } from 'reactstrap';
import api from '~/services/api';
import Loader from '~/components/Loader/Loader';
import moment from 'moment';

function ModalCreate({ showModal, toggle, loadPushes, dataToCopy, copyingData, setCopyingData, formatUserSubscriptionType }) {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [actionURL, setActionURL] = useState('');
    const [userSubscriptionType, setUserSubscriptionType] = useState('');
    const [userState, setUserState] = useState('');
    const [dueAt, setDueAt] = useState('');
    const [loading, setLoading] = useState(false);
    const [emptyTitle, setEmptyTitle] = useState(false);
    const [emptyContent, setEmptyContent] = useState(false);
    const [emptyDueAt, setEmptyDueAt] = useState(false);
    const [states, setStates] = useState([]);

    function clearErrors() {
        setEmptyTitle(false);
        setEmptyContent(false);
        setEmptyDueAt(false);
    }

    useEffect(() => {
        if (copyingData && dataToCopy) {
            setTitle(dataToCopy.title || '');
            setContent(dataToCopy.content || '');
            setActionURL(dataToCopy.action_url || '');
            setUserSubscriptionType(dataToCopy.user_subscription_type || '');
            setUserState(dataToCopy.user_state || '');
            setDueAt(dataToCopy.due_at ? moment(dataToCopy.due_at).format('YYYY-MM-DD HH:mm') : '');
            setCopyingData(false)
            clearErrors();
        }
    }, [copyingData, dataToCopy, setCopyingData])

    useEffect(() => {
        api.get('states').then(response => setStates(response?.data?.estados));
    }, []);

    async function handleSubmit() {
        try {
            clearErrors();

            if (moment(dueAt).isBefore()) {
                toast.warn('A data deve ser futura');
                return
            }

            setLoading(true);

            const data = {
                title,
                content,
                action_url: actionURL,
                user_subscription_type: userSubscriptionType === '0' ? '' : userSubscriptionType,
                user_state: userState === '0' ? '' : userState,
                due_at: moment(dueAt).utc().format('YYYY-MM-DDTHH:mm')
            };

            if (!data.title) setEmptyTitle(true);
            if (!data.content) setEmptyContent(true);
            if (!dueAt) setEmptyDueAt(true);

            if (!data.title || !data.content || !dueAt) {
                setLoading(false);
                return;
            }

            await api.post('push_notification_schedules', data);

            setLoading(false);

            toast.success('Push agendada com sucesso');

            loadPushes(1);
        } catch (error) {
            setLoading(false);

            toast.error('Ocorreu um erro ao agendar a push');
        }
    }

    const appendUserSubscriptionType = (value) => {
        if (userSubscriptionType) {
            if (value && !userSubscriptionType.split(',').includes(value)) {
                setUserSubscriptionType(userSubscriptionType + ',' + value);
            }
        } else {
            setUserSubscriptionType(value);
        }
    }

    const appendUserState = (value) => {
        if (userState) {
            if (value && !userState.split(',').includes(value)) {
                setUserState(userState + ',' + value);
            }
        } else {
            setUserState(value);
        }
    }

    const removeUserSubscriptionType = (value) => {
        if (userSubscriptionType && userSubscriptionType.split(',').includes(value)) {
            const filtered = userSubscriptionType.split(',').filter(item => item !== value);
            setUserSubscriptionType(filtered.join(','));
        }
    }

    const removeUserState = (value) => {
        if (userState && userState.split(',').includes(value)) {
            const filtered = userState.split(',').filter(item => item !== value);
            setUserState(filtered.join(','));
        }
    }

    return (
        <div>
            <Modal isOpen={showModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Agendar envio de push</ModalHeader>
                <ModalBody>
                    {loading && <Loader />}
                    <Form>
                        <FormGroup>
                            <Label for="title">Digite o título</Label>
                            <Input type="text" name="title" id="title" value={title} onChange={e => setTitle(e.target.value)} invalid={emptyTitle} />
                            <FormFeedback>Título é obrigatório</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="content">Conteúdo da push (Texto principal)</Label>
                            <Input type="textarea" rows={5} name="content" id="content" value={content} onChange={e => setContent(e.target.value)} invalid={emptyContent} />
                            <FormFeedback>Conteúdo é obrigatório</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="action_url">Link</Label>
                            <Input type="text" name="action_url" id="action_url" value={actionURL} onChange={e => setActionURL(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="user_subscription_type">Selecione o(s) tipo(s) de usuário</Label>
                            <Input type="select" name="user_subscription_type" id="user_subscription_type" onChange={e => appendUserSubscriptionType(e.target.value)}>
                                <option value="">Selecione</option>
                                <option value="ESSENTIAL">Essencial</option>
                                <option value="PERSONAL">Personal</option>
                                <option value="FREE">Gratuito</option>
                                <option value="BASIC">Básico</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Selecionados:&nbsp;</Label>
                            {
                                userSubscriptionType ?
                                    userSubscriptionType.split(',').map(value => (
                                        <Badge key={value} className="user-subscription-type-badge" onClick={() => removeUserSubscriptionType(value)} style={{ cursor: 'pointer' }}>
                                            {formatUserSubscriptionType(value)}&nbsp;&nbsp;X
                                        </Badge>
                                    ))
                                    :
                                    <Badge className="user-subscription-type-badge">
                                        Todos
                                    </Badge>
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label for="user_state">Selecione o(s) estado(s) do usuário</Label>
                            <Input type="select" name="user_state" id="user_state" onChange={e => appendUserState(e.target.value)}>
                                <option value="">Selecione</option>
                                {
                                    states.map(state => (
                                        <option key={state.nome} value={state.nome}>{state.nome}</option>
                                    ))
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Selecionados:&nbsp;</Label>
                            {
                                userState ?
                                    userState.split(',').map(value => (
                                        <Badge key={value} className="user-state-badge" onClick={() => removeUserState(value)} style={{ cursor: 'pointer' }}>
                                            {value}&nbsp;&nbsp;X
                                        </Badge>
                                    ))
                                    :
                                    <Badge className="user-state-badge">
                                        Todos
                                    </Badge>
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label for="due_at">Data para envio</Label>
                            <Input type="datetime-local" name="due_at" id="due_at" value={dueAt} onChange={e => setDueAt(e.target.value)} invalid={emptyDueAt} />
                            <FormFeedback>Data é obrigatória</FormFeedback>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>{' '}
                    <Button color="primary" onClick={handleSubmit}>Salvar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ModalCreate;