import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import Loader from '~/components/Loader/Loader';
import { adTypes } from '../utils';
import { toast } from 'react-toastify'
import api, { api2 } from '~/services/api'
import moment from 'moment';

export default function NewAdvertisingModal(props) {
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [url, setUrl] = useState('')
  const [type, setType] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  const [image, setImage] = useState('')
  const [location, setLocation] = useState([])
  const [active, setActive] = useState(true)
  const [subscriptionTypes, setSubscriptionTypes] = useState([])
  const [states, setStates] = useState([]);

  const modalTitle = props.editId ? 'Editar Propaganda' : 'Nova Propaganda'

  const getAds = useCallback(async () => {
    if (props.editId) {
      api2.get(`advertising/${props.editId}`)
        .then((response) => {
          const { data } = response

          setUrl(data.action_url)
          setExpirationDate(data.expiration_date ? moment(data.expiration_date).format('yyyy-MM-DD') : '')
          setImage(data.image)
          setTitle(data.title)
          setType(data.type)
          setLocation(data.locations || [])
          setActive(data.active)
          setSubscriptionTypes(data.subscription_types || [])
        })
        .catch(() => {
          toast.error('Erro ao editar a propaganda')
        })
    }
  }, [props.editId])

  const handleAdImage = async (event) => {
    if (event.target.files.length) {
      const file = event.target.files[0];

      const adImage = new FormData();

      adImage.append('file', file);

      setImageLoading(true)

      api2.post('/file/advertising-image', adImage)
        .then((response) => {
          const { data } = response;

          if (data.Location) {
            setImage(data.Location)
          }
        }).finally(() => {
          setImageLoading(false)
        });
    }
  };

  async function handleSaveAd() {
    if (title === '' || type === '' || url === '' || image === '') {
      toast.warn('Preencha todos os campos corretamente')
      return
    }

    const data = {
      title,
      action_url: url,
      type,
      expiration_date: expirationDate ? moment(expirationDate) : null,
      image,
      locations: location,
      active,
      subscription_types: subscriptionTypes
    }

    setLoading(true)

    try {
      if (props.editId) {
        await api2.put(`/advertising/${props.editId}`, data)
      } else {
        await api2.post('/advertising', data)
      }

      props.loadAds()

      setLoading(false)

      props.toggle()
    } catch (error) {
      setLoading(false)
      if (props.editId) {
        toast.error('Erro ao editar a propaganda')
      } else {
        toast.error('Erro ao cadastrar nova propaganda')
      }
    }
  }

  useEffect(() => {
    if (props.editId) {
      getAds()
    }
  }, [props.editId, getAds])

  const appendSubscriptionType = (value) => {
    setSubscriptionTypes(prev => [...prev, value])
  }

  const removeSubscriptionType = (value) => {
    setSubscriptionTypes(prev => prev.filter(subType => subType !== value))
  }

  useEffect(() => {
    api.get('states')
      .then(response => setStates(response?.data?.estados));
  }, []);

  const appendLocation = (value) => {
    setLocation(prev => [...prev, value])
  }

  const removeLocation = (value) => {
    setLocation(prev => prev.filter(userState => userState !== value))
  }

  return (
    <div>
      <Modal size="lg" isOpen={props.open} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>{modalTitle}</ModalHeader>

        <ModalBody>
          {loading && <Loader />}

          <Form>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="title">Título</Label>
                  <Input type="text" name="title" id="title" placeholder="Título" value={title} onChange={e => setTitle(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="link">Link</Label>
                  <Input type="text" name="link" id="link" placeholder="Link do banner" value={url} onChange={e => setUrl(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="type">Tipo</Label>
                  <Input type="select" name="type" id="type" placeholder="Tipo" value={type} onChange={e => setType(e.target.value)} >
                    {adTypes.map(type => <option key={type.value} value={type.value}>{type.name}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="expiration">
                    Data de validade
                  </Label>
                  <Input id="expiration" name="expiration" type="date" value={expirationDate} onChange={(e) => setExpirationDate(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col md={12}>
                <FormGroup check inline>
                  <Input type="checkbox" checked={active} onChange={(e) => setActive(e.target.checked)} />

                  <Label check>Ativo</Label>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="location">Selecione o(s) estado(s) do usuário</Label>
                  <Input type="select" name="location" id="location" onChange={e => appendLocation(e.target.value)}>
                    <option value="">Selecione</option>
                    {
                      states.map(state => (
                        <option key={state.nome} value={state.nome}>{state.nome}</option>
                      ))
                    }
                  </Input>
                </FormGroup>
                
                <FormGroup>
                  <Label>Selecionados:&nbsp;</Label>

                  {
                    !!location.length ?
                      location.map(value => (
                        <Badge key={value} onClick={() => removeLocation(value)} style={{ cursor: 'pointer', marginRight: 10 }}>
                          {value}&nbsp;&nbsp;X
                        </Badge>
                      ))
                      :
                      <Badge>
                        Todos
                      </Badge>
                  }
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="subscription_type">Selecione o(s) tipo(s) de usuário</Label>
                  <Input type="select" name="subscription_type" id="subscription_type" onChange={e => appendSubscriptionType(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="ESSENTIAL">Essencial</option>
                    <option value="PERSONAL">Personal</option>
                    <option value="BASIC">Básico</option>
                    <option value="FREE">Gratuito</option>
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label>Selecionados:&nbsp;</Label>

                  {
                    !!subscriptionTypes.length ?
                      subscriptionTypes.map(value => (
                        <Badge key={value} onClick={() => removeSubscriptionType(value)} style={{ cursor: 'pointer', marginRight: 10 }}>
                          {props.formatUserSubscriptionType(value)}&nbsp;&nbsp;X
                        </Badge>
                      ))
                      :
                      <Badge>
                        Todos
                      </Badge>
                  }
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="fileImage">
                    Imagem
                  </Label>

                  <Input
                    id="fileImage"
                    name="fileImage"
                    type="file"
                    onChange={handleAdImage}
                  />

                  <FormText>
                    Adicione a imagem do banner.
                  </FormText>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                {imageLoading && <Loader />}

                {image && <img alt={`${title}`} src={image} height={300} />}
              </Col>
            </Row>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" onClick={props.toggle}>Cancelar</Button>{' '}
          <Button color="success" onClick={handleSaveAd}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}