import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormFeedback, Badge } from 'reactstrap';
import api from '~/services/api';
import Loader from '~/components/Loader/Loader';
import moment from 'moment'

function ModalEdit({ push, showModal, toggle, loadPushes, copyToNewPushSchedule, formatUserSubscriptionType }) {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [actionURL, setActionURL] = useState('');
    const [userSubscriptionType, setUserSubscriptionType] = useState('');
    const [userState, setUserState] = useState('');
    const [dueAt, setDueAt] = useState('');
    const [loading, setLoading] = useState(false);
    const [emptyTitle, setEmptyTitle] = useState(false);
    const [emptyContent, setEmptyContent] = useState(false);
    const [emptyDueAt, setEmptyDueAt] = useState(false);
    const [states, setStates] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    useEffect(() => {
        setTitle(push.title || '');
        setContent(push.content || '');
        setActionURL(push.action_url || '');
        setUserSubscriptionType(push.user_subscription_type || '');
        setUserState(push.user_state || '');
        setDueAt(push.due_at ? moment(push.due_at).format('YYYY-MM-DD HH:mm') : '');
    }, [push]);

    useEffect(() => {
        api.get('states').then(response => setStates(response?.data?.estados));
    }, []);

    function clearErrors() {
        setEmptyTitle(false);
        setEmptyContent(false);
        setEmptyDueAt(false);
    }

    async function handleSubmit() {
        try {
            clearErrors();

            if (moment(dueAt).isBefore()) {
                toast.warn('A data deve ser futura');
                return
            }

            setLoading(true);

            const data = {
                title,
                content,
                action_url: actionURL,
                user_subscription_type: userSubscriptionType === '0' ? '' : userSubscriptionType,
                user_state: userState === '0' ? '' : userState,
                due_at: moment(dueAt).utc().format('YYYY-MM-DDTHH:mm')
            };

            if (!data.title) setEmptyTitle(true);
            if (!data.content) setEmptyContent(true);
            if (!data.due_at) setEmptyDueAt(true);

            if (!data.title || !data.content || !data.due_at) {
                setLoading(false);
                return;
            }

            await api.put(`push_notification_schedules/${push.id}`, data);

            setLoading(false);

            toast.success('Push atualizada com sucesso');

            loadPushes(1);
        } catch (error) {
            setLoading(false);

            toast.error('Ocorreu um erro ao atualizar a push');
        }
    }

    async function handleDelete() {
        try {
            setDeleteLoading(true);

            await api.delete(`push_notification_schedules/${push.id}`);

            setDeleteLoading(false);

            toast.success('Push apagada com sucesso');

            setModalDelete(prev => !prev);

            toggle();

            loadPushes(1);
        } catch (error) {
            setDeleteLoading(false);
            toast.error('Ocorreu um erro ao apagar a push');
        }
    }

    const appendUserSubscriptionType = (value) => {
        if (userSubscriptionType) {
            if (value && !userSubscriptionType.split(',').includes(value)) {
                setUserSubscriptionType(userSubscriptionType + ',' + value);
            }
        } else {
            setUserSubscriptionType(value);
        }
    }

    const appendUserState = (value) => {
        if (userState) {
            if (value && !userState.split(',').includes(value)) {
                setUserState(userState + ',' + value);
            }
        } else {
            setUserState(value);
        }
    }

    const removeUserSubscriptionType = (value) => {
        if (push?.status === 'PENDING' && userSubscriptionType && userSubscriptionType.split(',').includes(value)) {
            const filtered = userSubscriptionType.split(',').filter(item => item !== value);
            setUserSubscriptionType(filtered.join(','));
        }
    }

    const removeUserState = (value) => {
        if (push?.status === 'PENDING' && userState && userState.split(',').includes(value)) {
            const filtered = userState.split(',').filter(item => item !== value);
            setUserState(filtered.join(','));
        }
    }

    return (
        <div>
            <Modal isOpen={modalDelete} toggle={() => setModalDelete(prev => !prev)}>
                <ModalHeader toggle={() => setModalDelete(prev => !prev)}>Apagar push #{push.id}</ModalHeader>
                <ModalBody>
                    {deleteLoading && <Loader />}
                    Tem certeza?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModalDelete(prev => !prev)}>Não</Button>{' '}
                    <Button color="danger" onClick={handleDelete}>Sim</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={showModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{push?.status === 'PENDING' ? `Editar envio da push #${push.id}` : `Visualizando a push #${push.id}`}</ModalHeader>
                <ModalBody>
                    {loading && <Loader />}
                    <Form>
                        <FormGroup>
                            <Label for="title">Digite o título</Label>
                            <Input type="text" name="title" id="title" value={title} onChange={e => setTitle(e.target.value)} invalid={emptyTitle} disabled={push?.status === 'SENT'} />
                            <FormFeedback>Título é obrigatório</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="content">Conteúdo da push (Texto principal)</Label>
                            <Input type="textarea" rows={5} name="content" id="content" value={content} onChange={e => setContent(e.target.value)} invalid={emptyContent} disabled={push?.status === 'SENT'} />
                            <FormFeedback>Conteúdo é obrigatório</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="action_url">Link</Label>
                            <Input type="text" name="action_url" id="action_url" value={actionURL} onChange={e => setActionURL(e.target.value)} disabled={push?.status === 'SENT'} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="user_subscription_type">Selecione o(s) tipo(s) de usuário</Label>
                            <Input type="select" name="user_subscription_type" id="user_subscription_type" onChange={e => appendUserSubscriptionType(e.target.value)} disabled={push?.status === 'SENT'}>
                                <option value="">Selecione</option>
                                <option value="ESSENTIAL">Essencial</option>
                                <option value="PERSONAL">Personal</option>
                                <option value="FREE">Gratuito</option>
                                <option value="BASIC">Básico</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Selecionados:&nbsp;</Label>
                            {
                                userSubscriptionType ?
                                    userSubscriptionType.split(',').map(value => (
                                        <Badge key={value} className="user-subscription-type-badge" onClick={() => removeUserSubscriptionType(value)} style={{ cursor: push?.status === 'PENDING' ? 'pointer' : 'default' }}>
                                            {formatUserSubscriptionType(value)}&nbsp;&nbsp;X
                                        </Badge>
                                    ))
                                    :
                                    <Badge className="user-subscription-type-badge">
                                        Todos
                                    </Badge>
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label for="user_state">Selecione o(s) estado(s) do usuário</Label>
                            <Input type="select" name="user_state" id="user_state" onChange={e => appendUserState(e.target.value)} disabled={push?.status === 'SENT'}>
                                <option value="">Selecione</option>
                                {
                                    states.map(state => (
                                        <option key={state.nome} value={state.nome}>{state.nome}</option>
                                    ))
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Selecionados:&nbsp;</Label>
                            {
                                userState ?
                                    userState.split(',').map(value => (
                                        <Badge key={value} className="user-state-badge" onClick={() => removeUserState(value)} style={{ cursor: push?.status === 'PENDING' ? 'pointer' : 'default' }}>
                                            {value}&nbsp;&nbsp;X
                                        </Badge>
                                    ))
                                    :
                                    <Badge className="user-state-badge">
                                        Todos
                                    </Badge>
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label for="due_at">Data para envio</Label>
                            <Input type="datetime-local" name="due_at" id="due_at" value={dueAt} onChange={e => setDueAt(e.target.value)} invalid={emptyDueAt} disabled={push?.status === 'SENT'} />
                            <FormFeedback>Data é obrigatória</FormFeedback>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={copyToNewPushSchedule}>Copiar</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>{' '}

                    {
                        push.status !== 'SENDING' &&
                        <>
                            <Button color="danger" onClick={() => setModalDelete(prev => !prev)}>Apagar</Button>{' '}
                        </>
                    }

                    {
                        push?.status === 'PENDING' &&
                        <Button color="primary" onClick={handleSubmit}>Salvar</Button>
                    }
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ModalEdit;