import React, { useCallback, useEffect, useState } from 'react'
import MAClose from '@material-ui/icons/Close';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input, FormText, Badge } from 'reactstrap';
import Loader from '~/components/Loader/Loader';
import { toast } from 'react-toastify'
import { api2 } from '~/services/api'
import moment from 'moment';
import { BannerButton, BannerContainer, CloseButton, InfoContainer, Text, Title } from '../styles';

export default function NewHomeBannerModal(props) {
  const [loading, setLoading] = useState(false)
  const [hasModal, setHasModal] = useState(false)

  // banner customization
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState('')
  const [backgroundColor, setBackgroundColor] = useState('#2A6AC0')
  const [titleColor, setTitleColor] = useState('#ffffff')
  const [descriptionTextColor, setDescriptionTextColor] = useState('#ffffff')
  const [buttonText, setButtonText] = useState('')
  const [buttonColor, setButtonColor] = useState('#ffffff')
  const [buttonTextColor, setButtonTextColor] = useState('#000033')
  const [closeButtonColor, setCloseButtonColor] = useState('#ffffff')

  // close modal customization
  const [closeModalTitle, setCloseModalTitle] = useState('')
  const [closeModalDesc, setCloseModalDesc] = useState('')
  const [closeModalCloseButtonText, setCloseModalCloseButtonText] = useState('')
  const [closeModalConfirmButtonText, setCloseModalConfirmButtonText] = useState('')

  //banner behavior
  const [bannerConfirmButtonType, setBannerConfirmButtonType] = useState('URL')
  const [bannerConfirmButtonAction, setBannerConfirmButtonAction] = useState('')

  // banner config
  const [bannerSubscriptionType, setBannerSubescriptionType] = useState(['ESENTIAL', 'PERSONAL', 'BASIC', 'FREE'])
  const [expirationDate, setExpirationDate] = useState('')

  const modalTitle = props.editId ? 'Editar Banner' : 'Novo Banner'

  const getBanner = useCallback(async () => {
    if (props.editId) {
      api2.get(`home-banner/${props.editId}`)
        .then((response) => {
          const { data } = response

          setTitle(data.title)
          setDescription(data.description)
          setImage(data.image)
          setBackgroundColor(data.background_color)
          setTitleColor(data.title_color)
          setDescriptionTextColor(data.description_color)
          setButtonText(data.button_text)
          setButtonColor(data.button_color)
          setButtonTextColor(data.button_text_color)
          setCloseButtonColor(data.close_button_color)

          setCloseModalTitle(data.close_modal_title)
          setCloseModalDesc(data.close_modal_description)
          setCloseModalCloseButtonText(data.close_modal_close_button_text)
          setCloseModalConfirmButtonText(data.close_modal_confirm_button_text)

          setBannerConfirmButtonAction(data.banner_confirm_button_action)
          setBannerConfirmButtonType(data.banner_confirm_button_type)

          setBannerSubescriptionType(data.subscription_type)
          setExpirationDate(data.expiration_date ? moment(data.expiration_date).format('yyyy-MM-DD') : '')

          if (data.close_modal_title) {
            setHasModal(true)
          }
        })
        .catch(() => {
          toast.error('Erro ao editar o banner')
        })
    }
  }, [props.editId])

  const handleBannerImage = async (event) => {
    if (event.target.files.length) {
      const file = event.target.files[0];

      const bannerImage = new FormData();

      bannerImage.append('file', file);

      api2.post('/file/home-banner-image', bannerImage)
        .then((response) => {
          const { data } = response;

          if (data.Location) {
            setImage(data.Location)
          }
        });
    }
  };

  async function addSubscriptionType(value) {
    if (bannerSubscriptionType) {
      if (value && !bannerSubscriptionType.includes(value)) {
        setBannerSubescriptionType([...bannerSubscriptionType, value])
      }
    } else {
      setBannerSubescriptionType(value)
    }
  }

  async function removeSubcriptionType(value) {
    const filtered = bannerSubscriptionType.filter((type) => value !== type)
    setBannerSubescriptionType(filtered)
  }

  async function handleSaveBanner() {
    if (title === '' || description === '' || image === '' || bannerConfirmButtonAction === '' || buttonText === '') {
      toast.warn('Preencha os campos corretamente')
      return
    }

    const data = {
      title,
      description,
      image,
      background_color: backgroundColor,
      title_color: titleColor,
      description_text_color: descriptionTextColor,
      button_text: buttonText,
      button_color: buttonColor,
      button_text_color: buttonTextColor,
      close_button_color: closeButtonColor,
      close_modal_title: closeModalTitle,
      close_modal_description: closeModalDesc,
      close_modal_confirm_button_text: closeModalConfirmButtonText,
      close_modal_close_button_text: closeModalCloseButtonText,
      banner_confirm_button_type: bannerConfirmButtonType,
      banner_confirm_button_action: bannerConfirmButtonAction,
      subscription_type: bannerSubscriptionType,
      expiration_date: moment(expirationDate),
    }

    setLoading(true)

    try {
      if (props.editId) {
        await api2.put(`/home-banner/${props.editId}`, data)
      } else {
        await api2.post('/home-banner', data)
      }

      props.loadBanners()

      setLoading(false)

      props.toggle()
    } catch (error) {
      setLoading(false)
      if (props.editId) {
        toast.error('Erro ao editar o conteúdo')
      } else {
        toast.error('Erro ao cadastrar novo conteúdo')
      }
    }
  }

  useEffect(() => {
    if (props.editId) {
      getBanner()
    }
  }, [props.editId, getBanner])

  return (
    <div>
      <Modal size="xl" isOpen={props.open} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          {loading && <Loader />}
          <Form>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="title">Título</Label>
                  <Input type="text" name="title" id="title" placeholder="Título" value={title} onChange={e => setTitle(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="title">Descrição</Label>
                  <Input type="textarea" name="description" id="description" placeholder="Descrição" value={description} onChange={e => setDescription(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="button-text">Texto do Botão</Label>
                  <Input type="text" name="button-text" id="button-text" placeholder="Texto do botão" value={buttonText} onChange={e => setButtonText(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} >
                <FormGroup
                  check
                >
                  <Input id="check-modal" name="check-modal" type="checkbox" checked={hasModal} onChange={() => setHasModal(currentValue => !currentValue)} />
                  <Label for="check-modal" check>
                    Mostrar modal ao fechar o banner
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <br />
            {hasModal && <>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="modal-title">Título da Modal</Label>
                    <Input type="text" name="modal-title" id="modal-title" placeholder="Título da modal" value={closeModalTitle} onChange={e => setCloseModalTitle(e.target.value)} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="modal-description">Descrição da modal</Label>
                    <Input type="textarea" name="modal-description" id="modal-description" placeholder="Descrição da modal" value={closeModalDesc} onChange={e => setCloseModalDesc(e.target.value)} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="confirm-button">Texto do botão de confirmar</Label>
                    <Input type="text" name="confirm-button" id="confirm-button" placeholder="Texto botão de confirmar" value={closeModalConfirmButtonText} onChange={e => setCloseModalConfirmButtonText(e.target.value)} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="cancel-button">Texto do botão de cancelar</Label>
                    <Input type="text" name="cancel-button" id="cancel-button" placeholder="Texto botão de cancelar" value={closeModalCloseButtonText} onChange={e => setCloseModalCloseButtonText(e.target.value)} />
                  </FormGroup>
                </Col>
              </Row>
            </>}
            <Row className="align-items-center">
              <Col md={2}>
                <FormGroup>
                  <Label for="title-color">Cor do titulo</Label>
                  <Input type="color" name="title-color" id="title-color" value={titleColor} onChange={e => setTitleColor(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="desc-color">Cor da descrição</Label>
                  <Input type="color" name="desc-color" id="desc-color" value={descriptionTextColor} onChange={e => setDescriptionTextColor(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="background-color">Cor do fundo</Label>
                  <Input type="color" name="background-color" id="background-color" value={backgroundColor} onChange={e => setBackgroundColor(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="button-color">Cor do botão</Label>
                  <Input type="color" name="button-color" id="button-color" value={buttonColor} onChange={e => setButtonColor(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="button-text-color">Cor do texto do botão</Label>
                  <Input type="color" name="button-text-color" id="button-text-color" value={buttonTextColor} onChange={e => setButtonTextColor(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="close-button-color">Cor do botão de fechar</Label>
                  <Input type="color" name="close-button-color" id="close-button-color" value={closeButtonColor} onChange={e => setCloseButtonColor(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="user_subscription_type">Selecione o(s) tipo(s) de usuário</Label>
                  <Input type="select" name="user_subscription_type" id="user_subscription_type" onChange={e => addSubscriptionType(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="ESSENTIAL">Essencial</option>
                    <option value="PERSONAL">Personal</option>
                    <option value="FREE">Gratuito</option>
                    <option value="BASIC">Basic</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Selecionados:&nbsp;</Label>
                  {bannerSubscriptionType.map(value => (
                    <Badge key={value} className="user-subscription-type-badge" onClick={() => removeSubcriptionType(value)} style={{ cursor: 'pointer' }}>
                      {value}&nbsp;&nbsp;X
                    </Badge>
                  ))}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="expiration-date">Data de Vencimento</Label>
                  <Input type="date" name="expiration-date" id="expiration-date" value={expirationDate} onChange={e => setExpirationDate(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="redirect_type">Selecione o tipo de redirecionamento do banner</Label>
                  <Input type="select" name="redirect_type" id="redirect_type" value={bannerConfirmButtonType} onChange={e => setBannerConfirmButtonType(e.target.value)}>
                    <option value="URL">Site Extreno</option>
                    <option value="REDIRECT">Página da Aplicação</option>
                  </Input>
                </FormGroup>
              </Col>
              {bannerConfirmButtonType === "URL" && <Col md={6}>
                <FormGroup>
                  <Label for="URL">URL</Label>
                  <Input type="text" name="URL" id="URL" value={bannerConfirmButtonAction} onChange={e => setBannerConfirmButtonAction(e.target.value)} />
                </FormGroup>
              </Col>}
              {bannerConfirmButtonType === "REDIRECT" && <Col md={6}>
                <FormGroup>
                  <Label for="redirect_page">Selecione o tipo de redirecionamento do banner</Label>
                  <Input type="select" name="redirect_page" id="redirect_page" value={bannerConfirmButtonAction} onChange={e => setBannerConfirmButtonAction(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Progress">Tela de Progresso</option>
                    <option value="Club">Tela do Clube</option>
                    <option value="Training">Tela do Treino</option>
                    <option value="Profile">Tela de Perfil</option>
                  </Input>
                </FormGroup>
              </Col>}
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="fileImage">
                    Imagem
                  </Label>
                  <Input
                    id="fileImage"
                    name="fileImage"
                    type="file"
                    onChange={handleBannerImage}
                  />
                  <FormText>
                    Adicione a imagem banner.
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div>
                <h4>Preview Web</h4>
                <BannerContainer color={backgroundColor} image={image} width={600}>
                  <InfoContainer>
                    <Title color={titleColor}>{title ? title : 'Insira o título'}</Title>
                    <Text color={descriptionTextColor}>{description ? description : 'Insira a descrição'}</Text>
                    <BannerButton background={buttonColor} color={buttonTextColor}>{buttonText ? buttonText : 'Texto do botão'}</BannerButton>
                  </InfoContainer>
                  <CloseButton>
                    <MAClose style={{ fontSize: 20, color: closeButtonColor }} />
                  </CloseButton>
                </BannerContainer>
              </div>
              <div>
                <h4>Preview Mobile</h4>
                <BannerContainer color={backgroundColor} image={image} width={380}>
                  <InfoContainer>
                    <Title color={titleColor} size={15}>{title ? title : 'Insira o título'}</Title>
                    <Text color={descriptionTextColor} size={12}>{description ? description : 'Insira a descrição'}</Text>
                    <BannerButton background={buttonColor} color={buttonTextColor} fontSize={12}>{buttonText ? buttonText : 'Texto do botão'}</BannerButton>
                  </InfoContainer>
                  <CloseButton>
                    <MAClose style={{ fontSize: 20, color: closeButtonColor }} />
                  </CloseButton>
                </BannerContainer>
              </div>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.toggle}>Cancelar</Button>{' '}
          <Button color="success" onClick={handleSaveBanner}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}